<template>
  <b-modal
      v-model="visibleModal"
      title="Wybór konta Allegro"
      title-class="font-18"
      hide-footer
      centered
      @hide="hideModal"
      @esc="hideModal">

    <div class="form-group">
      <ecat-multiselect
          @change="value => form.allegroAccountId = value"
          label="Konto Allegro"
          placeholder="Wybierz konto Allegro"
          :fetch-one-url="`/allegro/${getSelectedShopId}`"
          :load-url="`/allegro/list/pagination/${getSelectedShopId}`"
          :query-url="`/allegro/by-name/${getSelectedShopId}`"
          save-id="id"
          view-id="name"
          param="name"
      />
    </div>

    <div class="text-right">
      <b-button :disabled="!form.allegroAccountId" @click="select" variant="success">Wybierz</b-button>
      <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
export default {

  data() {
    return {
      visibleModal: false,

      form: {
        allegroAccountId: null
      }
    }
  },

  computed: {
    getSelectedShopId() {
      return this.$store ? this.$store.state.shop.shopId : '';
    }
  },

  methods: {
    openModal() {
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.form.allegroAccountId = null
    },

    select() {
      this.$emit("select", this.form.allegroAccountId)
      this.hideModal()
    }
  }

}
</script>